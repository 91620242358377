import { useEffect, useState } from "react";
import Dispatcher from "./components/Dispatcher";
import { get, post, put } from "./lib/http/Http";
import { useForm } from "react-hook-form";
import App from "./App";

function Addresses({ dataset }) {
  const [customerId, setCustomerId] = useState();
  const [defaultValues, setDefaultValues] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [metafields, setMetafields] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
  });
  const onSubmit = (_data) => {
    const data = {
      ..._data,
    };
    console.log(data);
  };

  useEffect(() => {
    setCustomerId(window.__st?.cid);
  }, []);

  const retrieveCustomer = async () => {
    const response = await get({
      path: `applications/shopify/${customerId}`,
    });
    setMetafields(response.data.metafields);
    console.log(response.data);
    setAddresses(response.data.customer.addresses);
  };

  useEffect(() => {
    customerId && retrieveCustomer();
  }, [customerId]);

  useEffect(() => {
    if (metafields) {
      const billingAddress = metafields.find(
        (metafield) => metafield.key === "indirizzo_fatturazione_di_default"
      );
      if (billingAddress) {
        setAddressId(billingAddress.value);
      }
    }
  }, [metafields]);

  const submitBillingAddress = async (addressId) => {
    const response = await post({
      path: `applications/shopify/${customerId}/billing-address`,
      data: {
        data: {
          addressId,
        },
      },
    });
    console.log(response.data);
  };

  useEffect(() => {
    if (addressId) {
      submitBillingAddress(addressId);
    }
  }, [addressId]);

  return (
    <>
      <div className="cf-react-target">
        <div className="cf-form-inner">
          <div className="cf-form-step">
            <div
              key={"billing-address-key"}
              className="cf-field-container"
              data-field-name="phone"
              style={{ width: "100% !important" }}
              data-cf-column-width="12"
            >
              <div className="cf-field">
                <label
                  className="cf-field-label"
                  htmlFor="billing-address"
                  data-cf-error="Seleziona un indirizzo"
                >
                  Seleziona il tuo indirizzo di fatturazione di default
                </label>
                <select
                  className="cf-field-input"
                  name="billing-address"
                  id="billing-address"
                  {...register("billing-address")}
                  value={addressId}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => {
                    setAddressId(e.target.value);
                    // submitBillingAddress(e.target.value);
                  }}
                >
                  <option value="">Seleziona un indirizzo</option>
                  {addresses &&
                    addresses.map((address) => (
                      <option value={address.id}>
                        {address.address1}, {address.city}, {address.province} ({" "}
                        {address.first_name} {address.last_name}{" "}
                        {address.company})
                      </option>
                    ))}
                </select>
                <p
                  style={{
                    marginBottom: "30px",
                  }}
                >
                  <a href="/account/addresses" class="text-link">
                    Aggiungi un nuovo indirizzo o modificane uno, qui
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {customerId && <App />}
    </>
  );
}

export default Addresses;
